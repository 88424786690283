<template>
  <v-overlay v-model="localShowOverlay" persistent class="align-center justify-center">
    <CdeCard
      class="d-flex flex-column align-center justify-center pa-4 pa-md-16 position-relative custom-max-height"
      max-width="95vw"
      width="560px">
      <v-card-title class="text-center">
        <AtomsIconClose @click="handleClickBack" />

        <p class="text-h5 text-md-h4 font-weight-bold text-break">
          {{ t('different_zip_code') }}
        </p>

        <p class="text-h5 text-md-h4 font-weight-bold text-break">
          {{ t('in_shopping_cart') }}
        </p>
      </v-card-title>

      <v-row class="px-md-5">
        <v-col>
          <p class="py-md-8 font-weight-light">{{ t('shopping_cart_product_with_diff_zip') }}</p>

          <p class="align-self-start font-weight-bold text-h6">{{ t('current_cart') }}</p>
        </v-col>
      </v-row>

      <v-container max-height="180px" class="scrollable-container px-0">
        <v-row class="px-md-5">
          <v-col>
            <div class="scrollable-content">
              <CartSingleProduct
                v-for="product in products"
                :key="product.offerId"
                :product="product"
                :current-cart-delivery-address="currentCartDeliveryAddress" />
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-row class="mt-6 mb-1">
          <v-col cols="12" class="my-2 py-0 mb-6">
            <ButtonPrimary width="100%" min-height="80px" @click="handleClickBack">
              {{ t('back_to_container_selection') }}
            </ButtonPrimary>
          </v-col>

          <v-col cols="12" class="py-0">
            <ButtonSecondary width="100%" min-height="80px" @click="handleClickForwardToCart">
              {{ t('to_cart') }}
            </ButtonSecondary>
          </v-col>
        </v-row>
      </v-card-actions>
    </CdeCard>
  </v-overlay>
</template>

<script setup lang="ts">
import type { DeliveryAddress, Product } from '~/types/types'

const { t } = useI18n()

const props = defineProps<{
  modelValue: boolean
  products: Product[]
  currentCartDeliveryAddress: DeliveryAddress
}>()

const emits = defineEmits(['update:modelValue'])

const localShowOverlay = ref(props.modelValue)

watch(localShowOverlay, newValue => {
  emits('update:modelValue', newValue)
})

watch(
  () => props.modelValue,
  newValue => {
    localShowOverlay.value = newValue
  },
)

const handleClickBack = () => {
  localShowOverlay.value = false
}

const handleClickForwardToCart = async () => {
  localShowOverlay.value = false

  await navigateTo('/warenkorb')
}
</script>

<style scoped lang="scss">
.scrollable-container {
  max-height: 240px;
  overflow-y: auto;
}

.scrollable-content {
  display: flex;
  flex-direction: column;
}

.custom-max-height {
  max-height: max(240px, 96vh);
}
</style>
